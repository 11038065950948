import React, { Component } from 'react'
import {Link} from 'gatsby'
import {Container,
		Header,
		Image,
		Loader,
		//Icon,
		Grid} from 'semantic-ui-react'
import Seo from '../components/SEO'
import Layout from '../components/Layout'

import HowToOrder from '../components/HowToOrder'
import AccordionItem from '../components/AccordionItem'

import FAQList from '../components/FAQList'

import * as styles from './styles/pages.module.css'

import bannerdesktop from "../images/faq/banner-desktop.jpg"
import bannermobile from "../images/faq/banner-mobile.jpg"

//const Negosyo = require('../../lib/negosyo')
export default class Faq extends Component {
	state = {
/*
		pickupOptions:undefined,
		loadingPickup:true,
		errorLoadingPickup:false,
*/
		faqindex: 0
	}
/*
	componentDidMount() {

		Negosyo.getPickUpPoints()
		.then((addresses)=>{
			this.setState({pickupOptions:addresses, loadingPickup:false})
		}).catch((err)=>{
			this.setState({loadingPickup:false, errorLoadingPickup:true})
		})

	}

	pickUpOptionRender = (item) =>{
		return (
			<Grid.Row key={item.code}>
				<Grid.Column computer={15} tablet={14} mobile={13}>
					<li className={styles.listItem}>
						{item.name}:{item.address}
						<a
							href={`https://www.google.com/maps/search/NegosyoNow+${item.name.replace(/\s/g,'+')}/@${item.gps.latitude},${item.gps.longitude},18z`}
							target="_blank"
							rel="noopener noreferrer"
							title={`Link to google map location of ${item.name}`}
							className={styles.pickUpLink}
						>
							<Icon name="map marker alternate" size="regular" className={styles.mapicon}/>
						</a>
					</li>
				</Grid.Column>
			</Grid.Row>
		)
	}
*/
	faqClick = (index) => {
		if (this.state.faqindex === index) {
			this.setState({faqindex: 0})
		} else {
			this.setState({faqindex: index})
		}
	}

	render() {
		const location = this.props.location
		const {
/*
			loadingPickup,
			pickupOptions,
			errorLoadingPickup,
*/
			faqindex
		} = this.state
/*
		const {
			pickUpOptionRender
		} = this
*/
		return (
			<Layout location={location}>
				<Seo
						canonicalpath="/faq"
						title={"FAQs"}
						description="Frequently asked questions from our negosyantes.  Find answers to your questions, and find out more from questions others asked."
					/>
				<div>
					<Link to="/business">
						<Image style={{width:"100%"}} className={styles.desktopOnly} src={bannerdesktop} alt="Find the perfect partner for your business" />
						<Image style={{width:"100%"}} className={styles.mobileOnly} src={bannermobile} alt="Find the perfect partner for your business" />
					</Link>
				</div>
				<HowToOrder />
				{ false && <>
					<Header className={styles.header}> How can I order? </Header>
					<ul className={styles.text}>
						<li>
							Choose the products you'd like to buy.  One of our negosyo partners will contact you
							within the next business day after you check out.  You may choose to have your items
							delivered or you may pick them up at one of our showrooms.
						</li>
					</ul>
					</>
				}
				<Container className={styles.container}>
					<Header className={styles.superHeader+" "+styles.minBottomMargin}><h1>Frequently Asked Questions</h1></Header>
					<div className={styles.centerText}>Got Questions? Great, here's what you<div>need to know before we get started.</div></div>
					<FAQList
						list={[
							{"jumpid":"Cash-Upon-Delivery",
							"title":"How do I pay for my orders?",
							"content":<div className={styles.text}>
								We only accept Cash Upon Delivery or Cash Upon Pickup.
								We do accept payment via bank deposit, but only for selected type of transactions.
								But don't worry, we'll be providing more payment options soon!
							</div>},
							{"title":"Do you deliver?",
							"content":<>
								<div className={styles.text}>
									We currently have limited delivery services, kindly place your order indicating
									your location and we will contact you to advise you if we have deliveries in your area.
								</div>
							</>},
							{"title":"Can you send orders to a bus terminal in my location?",
							"content":<>
								<div className={styles.text}>
									Yes, we do ship orders via selected bus services.
									Please indicate the service and terminal in your order, and we'll advise you if it is possible.
								</div>
								<Link to="/pick-up-transactions/#bus-shipping">Learn more about Bus Shipping</Link>
							</>},
							{"title":"Can I pick up my orders?",
							"content":<>
								<div className={styles.text}>
									Yes you can! You may choose to have your order picked up when you check out.
									We'll then contact you when your orders are ready for pick up at your selected location.
								</div>
								<Link to="/pick-up-transactions/">Learn more about Pick Up Locations and Transactions</Link>
							</>},
							{"title":"Can I book pick up services to claim my orders for me?",
							"content":<>
								<div className={styles.text}>
									Yes you can use Grab, Lalamove, etc to get the items for you! Kindly indicate the service you'd like to avail in your order so our team can prepare accordingly.
								</div>
								<Link to="/pick-up-transactions/#third-party">Learn more about Customer-Booked Pick Up</Link>
							</>},
							{"title":"How do I confirm my order?",
							"content":<>
								<div className={styles.text}>
									You will receive a NegosyoNow authorization code via SMS after successful checkout.  <Link to="/confirm/">Please enter the code here to confirm your order</Link>.
								</div>
							</>},
							{"title":"Do you accept returns or refunds?",
							"content":<>
								<div className={styles.text}>
									Issues and concerns regarding goods received or picked up may be raised through our
									communication channels within 7 days of receipt.  The resolution will be decided
									on a case to case basis.
								</div>
							</>},
							{"title":"I can't find what I'm looking for, do you have other products?",
							"content":<>
								<div className={styles.text}>
									We'd like to hear you out. Let us know what items you have in mind using our <Link to={"/request-product/"}>Request a Product</Link> form
								</div>
							</>},
						]}
						/>
				</Container>
			</Layout>
		)
	}
}

